import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';

import { Autoplay, FreeMode } from 'swiper/modules';

const logos = [
  {
    src: '/images/logos/amazon.svg',
    alt: 'Amazon Logo',
    href: '/vmc-validator?domain=amazon.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/paypal.svg',
    alt: 'Paypal Logo',
    href: '/vmc-validator?domain=paypal.com',
    height: 100,
    width: 100,
  },
  /* {
    src: '/images/logos/linkedin.svg',
    alt: 'LinkedIn Logo',
    href: '/vmc-validator?domain=linkedin.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/uber.svg',
    alt: 'Uber Logo',
    href: '/vmc-validator?domain=uber.com',
    height: 100,
    width: 100,
  }, */
  {
    src: '/images/logos/capital-one.svg',
    alt: 'Capital One Logo',
    href: '/vmc-validator?domain=capitalone.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/american-express.svg',
    alt: 'American Express Logo',
    href: '/vmc-validator?domain=americanexpress.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/redbull.svg',
    alt: 'Redbull Logo',
    href: '/vmc-validator?domain=redbull.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/mcafee.svg',
    alt: 'McAfee Logo',
    href: '/vmc-validator?domain=mcafee.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/cvs.svg',
    alt: 'CVS Logo',
    href: '/vmc-validator?domain=cvs.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/zoom.svg',
    alt: 'Zoom Logo',
    href: '/vmc-validator?domain=zoom.us',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/dropbox.svg',
    alt: 'Dropbox Logo',
    href: '/vmc-validator?domain=dropbox.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/twillio.svg',
    alt: 'Twilio Logo',
    href: '/vmc-validator?domain=twilio.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/siemensag.svg',
    alt: 'Siemens AG Logo',
    href: '/vmc-validator?domain=siemens.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/vimeo.svg',
    alt: 'Vimeo Logo',
    href: '/vmc-validator?domain=vimeo.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/crowdstrike.svg',
    alt: 'CrowdStrike Logo',
    href: '/vmc-validator?domain=crowdstrike.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/clorox.svg',
    alt: 'Clorox Logo',
    href: '/vmc-validator?domain=clorox.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/yelp.svg',
    alt: 'Yelp Logo',
    href: '/vmc-validator?domain=yelp.com',
    height: 100,
    width: 100,
  },
  {
    src: '/images/logos/rakuten.svg',
    alt: 'Rakuten Logo',
    href: '/vmc-validator?domain=rakuten.com',
    height: 100,
    width: 100,
  },
];

const Carousel = ({ enableLinks = false }) => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  /* useEffect(() => {
    const swiperContainer = swiperRef.current;
    if (swiperContainer) {
      const swiperInstance = swiperContainer.swiper;

      const handleMouseEnter = () => {
        if (swiperInstance && swiperInstance.autoplay) {
          swiperInstance.autoplay.stop();
        }
      };

      const handleMouseLeave = () => {
        if (swiperInstance && swiperInstance.autoplay) {
          swiperInstance.autoplay.start();
        }
      };

      swiperContainer.addEventListener('mouseenter', handleMouseEnter);
      swiperContainer.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        swiperContainer.removeEventListener('mouseenter', handleMouseEnter);
        swiperContainer.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []); */

  const renderLogo = (logo, index) => {
    const image = <Image src={logo.src} alt={logo.alt} height={logo.height} width={logo.width} />;

    return enableLinks ? (
      <Link href={logo.href} key={index} target="_blank" rel="noopener noreferrer">
        {image}
      </Link>
    ) : (
      image
    );
  };

  // Duplicate the logos array to ensure a seamless loop
  const extendedLogos = [...logos];

  return (
    <div className="carousel-container">
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, FreeMode]}
        spaceBetween={100}
        slidesPerView={6}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        speed={4000}
        // onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="mySwiper"
      >
        {extendedLogos.map((logo, index) => (
          <SwiperSlide key={index}>
            <div className="logos">
              {renderLogo(logo, index)}
              <svg
                className="icon"
                width="154"
                height="140"
                viewBox="0 0 154 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M76.7 112.645C100.266 112.645 119.37 93.3248 119.37 69.4919C119.37 45.659 100.266 26.3386 76.7 26.3386C53.1337 26.3386 34.0295 45.659 34.0295 69.4919C34.0295 93.3248 53.1337 112.645 76.7 112.645Z"
                  fill="white"
                />
                <path
                  d="M53.4818 139.185L40.2768 117.736L14.0406 112.621L16.9943 88.3674L0.314331 69.8883L16.9943 51.5742L14.0406 27.3203L40.2768 22.2055L53.4818 0.591553L76.7643 10.8211L100.047 0.591553L113.426 22.2055L139.488 27.3203L136.534 51.5742L153.214 69.8883L136.534 88.3674L139.488 112.621L113.426 117.736L100.047 139.185L76.7643 128.956L53.4818 139.185ZM69.2931 91.8323L108.734 54.709L100.916 47.9443L69.2931 77.6429L52.7868 61.3087L44.7943 68.7334L69.2931 91.8323Z"
                  fill="#247AFE"
                />
              </svg>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
